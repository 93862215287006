import React from "react";

function Heading({ no, title, description, variant = "default" }) {
    const noColor = getNoColor(variant);
    const headerColor = getHeaderColor(variant);
    const descriptionColor = getDescriptionColor(variant);

    return <div className="flex-ns mb4 mt4 mt0-l">
        <div>
            <h3 className={ `${noColor} dib f-subheadline f-headline-l ma0 pa0` }>
                {no}
            </h3>
        </div>
        <div className="pl2 pl3-ns">
            <h2 className={ `${headerColor} mb2 mb1-l pb0 f3 f2-ns mt1 mt2-m mt3-l pt0 b` }>
                {title}
            </h2>
            <p className={ `${descriptionColor} pt0 mt0 f5 f4-ns fw7 lh-copy f5-ns` }>
                { description }
            </p>
        </div>
        <div className="flex-grow-1"></div>
    </div>
}

function getNoColor(variant) {
    switch (variant) {
        case "white":
            return "white-transparent";
        case "dark":
            return "purple-transparent";
        default:
            return 'gradient-purple-blue';
    }
}

function getHeaderColor(variant) {
    switch (variant) {
        case "white":
        case "dark":
            return "white";
        default:
            return 'black-80';
    }
}

function getDescriptionColor(variant) {
    switch (variant) {
        case "white":
        case "dark":
            return "white-60";
        default:
            return 'black-40';
    }
}

export default Heading;