import React from "react";
import Image from "../images/arrow.svg";

function Arrow({ onClick, className = "" }) {
    return <div className={ "ba bw1 br-pill b--white-30 is-50x50 lh-50 tc align-center pointer " + className } onClick={onClick} style={{
        width: "45px",
        height: "45px",
        lineHeight: "55px",
    }}>
        <Image />
    </div>
}

export default Arrow;