import React from "react"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = site.siteMetadata.title || title
  const ogImageUrl = site.siteMetadata.siteUrl + '/images/ogimage.png'

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `format-detection`,
          content: `telephone=no`,
        },
        {
          name: `image`,
          content: ogImageUrl,
        },
        {
          name: `og:image`,
          content: ogImageUrl,
        },
        {
          name: `twitter:image`,
          content: ogImageUrl,
        }
      ].concat(meta)}
    >
      <meta name="image" content={ogImageUrl} />
      <meta name="og:image" property="og:image" content={ogImageUrl} />
      <meta name="twitter:image" content={ogImageUrl} />
      <meta name="description" content={metaDescription} />
      <meta name="og:description" property="og:description" content={metaDescription} />
      <meta name="og:title" property="og:title" content={metaTitle} />
      <meta name="twitter:title" content={title} />
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

export default SEO
