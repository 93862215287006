import React from "react";
import Image from "../images/dark-arrow.svg";

function Arrow({ onClick }) {
    return <div className="ba bw1 br-pill b--black-30 is-50x50 lh-50 tc align-center pointer" onClick={onClick} style={{
        width: "45px",
        height: "45px",
        lineHeight: "55px",
    }}>
        <Image />
    </div>
}

export default Arrow;