import React from "react";
import Logo from "../images/logo-alt.svg";

function Footer() {
    return <div className="bg-gradient-blue-purple pa4 pa4-m pa5-l" style={{
        boxShadow: "0 0px 60px 0 rgba(0, 0, 0, 0.6)"
    }}>
        <div>
            <div class="mw9 center flex-l">
                <div>
                    <div>
                        <Logo />
                    </div>
                    <div className="mt4 white-60">
                        <p>Nobel Tower</p>
                        <p>Dąbrowskiego 77a</p>
                        <p>60-529 Poznań, Poland</p>
                    </div>
                    <div className="mt4 white-60">
                        <p>NIP/VAT ID</p>
                        <p>PL7822821382</p>
                    </div>
                </div>
                <div class="flex-grow-1"></div>
                <div className="tr-l">
                    <div className="mt4">
                        <a href="mailto:office@framecoders.com" className="f4 f3-l white b no-underline dim">
                            office@framecoders.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Footer;