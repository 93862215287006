import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

function HeroImage() {
    const data = useStaticQuery(graphql`
    {
        file(relativePath: { eq: "hero-cover.png" }) {
            childImageSharp {
                fluid(maxWidth: 890, quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }`);

    return <Img fluid={data.file.childImageSharp.fluid} className="center" style={{ 
        maxWidth: "890px",
        height: "auto"
    }} />
}

export default HeroImage;